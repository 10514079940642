import React, {useState, useEffect} from "react";
import API from "../utils/API";

// Section for Utilities and Context
import "./landing.css";
// import data from "../utils/test.json";

// Section for Components
import laptopPic from "../assets/laptop.png";
import landingPic2 from "../assets/eg-img-4.png";
import LoginModal from "../components/LoginModal";
import RegisterModal from "../components/RegisterModal";


// MAIN FUNCTION
export default function Landing() {
    return (
        <>
          
            {/* <div className="row landing-image-row">
                    
            </div> */}
            <div className="landing-header row ">
                <div className="col-lg-5">
                    <h3 className="landing-header-text display-6">Directory. Public Square. Newstand.</h3>
                    <h1 className="landing-header-tagline display-6">Say Hello to Government Relations.</h1>
                    <hr />
                    <br />
                </div>
                <div className="col-lg-2"/>
                
            </div>
            <div className="row landing-row mt-5">
                <div className="col-lg-7">
                    <p className="landing-header-subtext display-6"><strong><i>'ello</i>Govna</strong> is the new directory for legislative and regulatory affairs professionals working with the State of California to stay connected and informed about news and your colleagues to keep government working.</p>

                </div>

            </div>
            <div className="row landing-row my-lg-5 mb-4">
                <button className="btn landing-register-button me-md-2 mb-2 mb-md-0" data-bs-toggle="modal" data-bs-target="#registerModal">Register Today!</button>
                <button className="btn landing-login-button ms-md-2 mt-2 mt-md-0" data-bs-toggle="modal" data-bs-target="#loginModal">Login</button>

            </div>
            <div className="row light-row pt-5">
                <div className="col-lg-7">
                    <h5>Whether you're a lobbyist, staffer, board member, or information officer, <strong><i>'ello</i>Govna</strong> can meet your government affairs needs!</h5>
                </div>            
            </div>
            <div className="row light-row py-5">
                <div className="laptop-col col-lg-3">
                    <img className="landing-laptop-pic img-fluid" src={laptopPic}/>
                </div>
                
                <div className="col-lg-5">
                    <span className="landing-text display-6">
                        <ul>
                            <li>Looking for the right legislator to author your policy proposal?</li><br/>
                            <li>Need to find a staffer working on cybersecurity issues?</li><br/>
                            <li>Just got elected and need to post job openings in your office?</li><br/>
                            <li>Holding a press conference or hosting a fundraiser for a candidate?</li><br/>
                            <li>Building support for your bill and want to rally the right stakeholders?</li><br/>
                        </ul>
                    </span>
                </div>                    
            </div>
            <div className="row landing-row pt-5">
                <div className="col-lg-7">
                    <p className="landing-header-subtext display-6"><span href="" className="landing-links" data-bs-toggle="modal" data-bs-target="#registerModal">Sign-up</span> to scroll through the day's headlines, find the staff or stakeholders you need to connect with, post announcements, comment on your colleagues' posts, and more!</p>
                    <br />
                    <p>If you're not able to register and work for or do business with the State of California, please reach out to <a href="mailto:info@ellogovna.com">info@ellogovna.com</a> to request access.</p>
                </div>            
            </div>

            <div className="row primary-row py-3">
                <span className="eg-copyright">&#169; <i>'ello</i>Govna 2024</span>
            </div>
            {/* <div className="position-absolute start-50 top-50 translate-middle welcome-div">
                    <h1 className="display-1 landing-welcome-title"><i>'ello</i>, Govna!</h1>
                    <hr/>
                    <p className="welcome-message"><strong><i>'ello</i>Govna</strong> is the new directory for legislative and regulatory affairs professionals working with the State of California to stay connected and informed about news and your colleagues to keep government working.<br/><br/>Here you'll be able to scroll through the day's headlines, find the staff working on the issue you need amendments on, post announcements, and even comment on your colleagues' posts.<br/><br/>If you find you're not able to register and work for or do business with the State of California, please reach out to <a href="mailto:info@ellogovna.com">info@ellogovna.com</a> to request access.</p>
                    <hr/>
                    <h1 className="display-6 landing-login-register">Please <span className="landing-links" data-bs-toggle="modal" data-bs-target="#loginModal">login</span> or <a href="" className="landing-links" data-bs-toggle="modal" data-bs-target="#registerModal">register</a> a new account!</h1>
                    <br/>
            </div> */}

            <LoginModal/>
            <RegisterModal/>
        </>
    )
}